import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms/src/directives/ng_form';
import { Observable } from 'rxjs';
import { Carro } from '../carro';
import { Correo } from '../correo';
import * as emailjs from 'emailjs-com';


@Component({
  selector: 'app-carros',
  templateUrl: './carros.component.html',
  styleUrls: ['./carros.component.css']
})

export class CarrosComponent implements OnInit {
  //emailjs.init('user_l9CXwyGYyFatktrj1Qhmk');
  modeloCorreo = new Correo("", "", "", "", "", "", "", new Carro("", "", "", "", "", ""));
  carroSel: Carro;
  submitted = false;
  mensajeHtml: string;
  clienteHtml: string;
  templateParam: {};
  carros: Observable<any[]>;
  constructor(db: AngularFirestore) {
    this.carros = db.collection('carros').valueChanges();
    /*this.carros.forEach(car => {
      console.log(car);
    });*/
  }

  ngOnInit() {

  }

  onSubmit(f: any) {
    if (f.valid === true && this.carroSel != undefined) {
      //this.submitted = true;
      this.mensajeHtml = "<h3>Datos de carro</h3><ul>" +
        "<li><strong>Nombre:</strong> " + this.modeloCorreo.carro.nombre + "</li>" +
        "<li><strong>Tipo:</strong> " + this.modeloCorreo.carro.tipo + "</li>" +
        "<li><strong>Puestos:</strong> " + this.modeloCorreo.carro.puestos + "</li>" +
        "<li><strong>Cambios:</strong> " + this.modeloCorreo.carro.cambios + "</li>" +
        "<li><strong>Maletas:</strong> " + this.modeloCorreo.carro.maletas + "</li>" +
        "</ul>";

      this.clienteHtml = "<h3>Datos de cliente</h3><ul>" +
        "<li><strong>Nombre:</strong> " + this.modeloCorreo.nombre + "</li>" +
        "<li><strong>Telefono:</strong> " + this.modeloCorreo.telefonoUsuario + "</li>" +
        "<li><strong>Email:</strong> " + this.modeloCorreo.correoUsuario + "</li>" +
        "<li><strong>Desde la fecha:</strong> " + this.modeloCorreo.fechaDesde + "</li>" +
        "<li><strong>Hasta la fecha:</strong> " + this.modeloCorreo.fechaHasta + "</li>" +
        "<li><strong>Mensaje:</strong> " + this.modeloCorreo.detalles + "</li>" +
        "</ul>";

      this.mensajeHtml = this.clienteHtml + this.mensajeHtml;
      this.templateParam = {
        name: "DINAMO",
        notes: "Solicitud",
        from_name: this.modeloCorreo.nombre,
        from_email: this.modeloCorreo.correoUsuario,
        message_html: this.mensajeHtml
      };
      emailjs.send('gmail', 'template_cotizacion', this.templateParam, 'user_l9CXwyGYyFatktrj1Qhmk').then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert("Mensaje enviado!");
        f.resetForm();
      }, (err) => {
        console.log('FAILED...', err);
      });
      
    } else {
      alert("Por favor diligencie correctamente el formulario!");
    }
  }

  seleccionarCarro(carro: Carro): void {
    this.modeloCorreo.carro = carro;
    this.carroSel = carro;
  }

}
