import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  carros: Observable<any[]>;

  constructor(db: AngularFirestore) {
    //this.carros = db.collection('carros').valueChanges();
    this.carros = db.collection('carros', ref => ref.limit(3)).valueChanges();
  }

  ngOnInit() {

  }

}
