import { Carro } from './carro';

export class Correo {
      constructor(
            public nombre: string,
            public ciudad: string,
            public fechaDesde: string,
            public fechaHasta: string,
            public correoUsuario: string,
            public telefonoUsuario: string,
            public detalles: string,
            public carro:Carro,
      ) {}
}