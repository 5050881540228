import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { CarrosComponent } from './carros/carros.component';
import { ContactenosComponent } from './contactenos/contactenos.component';
import { LocalizacionComponent } from './localizacion/localizacion.component';


const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'carros', component: CarrosComponent },
  { path: 'contactenos', component: ContactenosComponent },
  { path: 'localizacion', component: LocalizacionComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
