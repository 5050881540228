import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms/src/directives/ng_form';
import { Contacto } from '../contacto';
import * as emailjs from 'emailjs-com';

@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.css']
})

export class ContactenosComponent implements OnInit {

  contacto = new Contacto("","","","");
  mensajeHtml: string;
  templateParam: {};
  
  constructor() { }

  ngOnInit() {
  }

  enviarMensaje(f: NgForm){
    if(f.valid === true){
      this.mensajeHtml = "<h3>Datos de carro</h3><ul>" +
        "<li><strong>Nombre:</strong> " + this.contacto.nombre + "</li>" +
        "<li><strong>Correo electrónico:</strong> " + this.contacto.email + "</li>" +
        "<li><strong>Aunto:</strong> " + this.contacto.asunto + "</li>" +
        "<li><strong>Mensaje:</strong> " + this.contacto.mensaje + "</li>" +
        "</ul>";
      this.templateParam = {
        name: "DINAMO",
        notes: "Mensaje de contactenos",
        from_name: this.contacto.nombre,
        from_email: this.contacto.email,
        message_html: this.mensajeHtml
      };
      emailjs.send('gmail', 'template_contactos', this.templateParam, 'user_l9CXwyGYyFatktrj1Qhmk').then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert("Mensaje enviado!");
        f.resetForm();
      }, (err) => {
        console.log('FAILED...', err);
      });
      alert("Mensaje enviado!");
    } else {
      alert("Por favor diligencie correctamente el formulario!");
    }
  }

}
