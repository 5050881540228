export class Carro{
      constructor(
            public cambios: string,
            public imagen: string,
            public maletas: string,
            public nombre: string,
            public puestos: string,
            public tipo:string,
      ) {}
      
}