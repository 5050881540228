import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
//import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
//import { AgmCoreModule } from '@agm/core'; //google maps
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { CarrosComponent } from './carros/carros.component';
import { ContactenosComponent } from './contactenos/contactenos.component';
import { LocalizacionComponent } from './localizacion/localizacion.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ServiciosComponent,
    CarrosComponent,
    ContactenosComponent,
    LocalizacionComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LeafletModule.forRoot(),
    /*AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDWKdMqIz5709lVQkEMhxfyeMxJK23IXnU'
    }),*/
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule // imports firebase/storage only needed for storage features
    //AngularFireDatabaseModule // Realtime database
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }